import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/Home.vue'
import AdminDashboard from '@/views/Admin/AdminDashboard.vue'
import ProjectManagement from '@/views/Admin/ProjectManagement.vue'
import ProductManagement from '@/views/Admin/ProductManagement.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboard,
    children: [
      {
        path: 'projects',
        name: 'ProjectManagement',
        component: ProjectManagement
      },
      {
        path: 'products',
        name: 'ProductManagement',
        component: ProductManagement
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router