<template>
    <div class="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <nav>
        <router-link to="/admin/projects">Manage Projects</router-link> |
        <router-link to="/admin/products">Manage Products</router-link>
      </nav>
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AdminDashboard'
  }
  </script>
  
  <style scoped>
  .admin-dashboard {
    padding: 20px;
  }
  
  nav {
    margin-bottom: 20px;
  }
  </style>