import { createStore } from 'vuex'
import axios from 'axios'

const API_URL = 'http://localhost:8080/api' // 假设这是您的 Gin 后端 API 地址

export default createStore({
  state: {
    projects: [],
    products: []
  },
  mutations: {
    setProjects(state, projects) {
      state.projects = projects
    },
    setProducts(state, products) {
      state.products = products
    },
    addProject(state, project) {
      state.projects.push(project)
    },
    addProduct(state, product) {
      state.products.push(product)
    },
    deleteProject(state, id) {
      state.projects = state.projects.filter(project => project.id !== id)
    },
    deleteProduct(state, id) {
      state.products = state.products.filter(product => product.id !== id)
    }
  },
  actions: {
    async fetchProjects({ commit }) {
      try {
        const response = await axios.get(`${API_URL}/projects`)
        commit('setProjects', response.data)
      } catch (error) {
        console.error('Error fetching projects:', error)
      }
    },
    async fetchProducts({ commit }) {
      try {
        const response = await axios.get(`${API_URL}/products`)
        commit('setProducts', response.data)
      } catch (error) {
        console.error('Error fetching products:', error)
      }
    },
    async addProject({ commit }, project) {
      try {
        const response = await axios.post(`${API_URL}/projects`, project)
        commit('addProject', response.data)
      } catch (error) {
        console.error('Error adding project:', error)
      }
    },
    async addProduct({ commit }, product) {
      try {
        const response = await axios.post(`${API_URL}/products`, product)
        commit('addProduct', response.data)
      } catch (error) {
        console.error('Error adding product:', error)
      }
    },
    async deleteProject({ commit }, id) {
      try {
        await axios.delete(`${API_URL}/projects/${id}`)
        commit('deleteProject', id)
      } catch (error) {
        console.error('Error deleting project:', error)
      }
    },
    async deleteProduct({ commit }, id) {
      try {
        await axios.delete(`${API_URL}/products/${id}`)
        commit('deleteProduct', id)
      } catch (error) {
        console.error('Error deleting product:', error)
      }
    }
  }
})