<template>
    <div class="product-management">
      <h1>Product Management</h1>
      
      <form @submit.prevent="addProduct">
        <input v-model="newProduct.name" placeholder="Product Name" required>
        <input v-model="newProduct.imageUrl" placeholder="Image URL" required>
        <button type="submit">Add Product</button>
      </form>
  
      <div class="product-grid">
        <div v-for="product in products" :key="product.id" class="product-item">
          <img :src="product.imageUrl" :alt="product.name">
          <p>{{ product.name }}</p>
          <button @click="editProduct(product)">Edit</button>
          <button @click="deleteProduct(product.id)">Delete</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  
  export default {
    name: 'ProductManagement',
    data() {
      return {
        newProduct: {
          name: '',
          imageUrl: ''
        }
      }
    },
    computed: {
      ...mapState(['products'])
    },
    methods: {
      addProduct() {
        this.$store.dispatch('addProduct', this.newProduct)
        this.newProduct = { name: '', imageUrl: '' }
      },
      editProduct(product) {
        // Implement edit functionality
        console.log('Edit product:', product)
      },
      deleteProduct(id) {
        this.$store.dispatch('deleteProduct', id)
      }
    },
    mounted() {
      this.$store.dispatch('fetchProducts')
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .product-management {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  
    form {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
  
      input, button {
        padding: 10px;
      }
    }
  
    .product-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 20px;
  
      .product-item {
        text-align: center;
        background-color: #f0f0f0;
        border-radius: 8px;
        padding: 20px;
  
        img {
          max-width: 100%;
          height: auto;
          border-radius: 50%;
          margin-bottom: 10px;
        }
  
        button {
          margin-top: 10px;
          margin-right: 5px;
        }
      }
    }
  }
  </style>