<template>
    <div class="project-management">
      <h1>Project Management</h1>
      
      <form @submit.prevent="addProject">
        <input v-model="newProject.name" placeholder="Project Name" required>
        <input v-model="newProject.description" placeholder="Project Description" required>
        <button type="submit">Add Project</button>
      </form>
  
      <ul class="project-list">
        <li v-for="project in projects" :key="project.id" class="project-item">
          <h3>{{ project.name }}</h3>
          <p>{{ project.description }}</p>
          <button @click="editProject(project)">Edit</button>
          <button @click="deleteProject(project.id)">Delete</button>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  
  export default {
    name: 'ProjectManagement',
    data() {
      return {
        newProject: {
          name: '',
          description: ''
        }
      }
    },
    computed: {
      ...mapState(['projects'])
    },
    methods: {
      addProject() {
        this.$store.dispatch('addProject', this.newProject)
        this.newProject = { name: '', description: '' }
      },
      editProject(project) {
        // Implement edit functionality
        console.log('Edit project:', project)
      },
      deleteProject(id) {
        this.$store.dispatch('deleteProject', id)
      }
    },
    mounted() {
      this.$store.dispatch('fetchProjects')
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .project-management {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  
    form {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
  
      input, button {
        padding: 10px;
      }
    }
  
    .project-list {
      list-style-type: none;
      padding: 0;
  
      .project-item {
        background-color: #f0f0f0;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 10px;
  
        button {
          margin-right: 10px;
        }
      }
    }
  }
  </style>